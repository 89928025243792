
.yukimi-layout {
    min-height: 100vh;
}

.rainy-day {
    background: linear-gradient(to bottom, #202020, #111119);
    color: floralwhite;
}

.header {
    background-color: transparent;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.display-options {
    align-self: flex-end;
    font-size: 200%;
}

.feed {
    width: 1280px;
    max-width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.youtube-thumbnail {
    width: 240px;
    max-width: 33vw;
}

.card-meta {
    display: flex;
    align-items: center;
}

.licence {
    width: 900px;
    max-width: 75vw;

    .licence-header {
        background-color: #00b5da;
        color: white;
        padding: 20px;
        text-align: center;

        h1 {
            color: white;
            font-size: 200%;
            font-weight: bold;
            width: 540px;
            max-width: 50vw;
            margin: 0 auto;
        }
    }

    .licence-icon-group {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        
        img {
            width: 7%;
            min-width: 50px;
        }
    }

    .licence-content {
        font-size: 125%;
        text-align: center;

        h1 {
            font-weight: bold;
            font-size: 125%;
        }
    }
}

.small-icon {
    background-color: #00b5da;
    width: 70%;
    max-width: 20vw;
    padding: 10px;
}

.modal-wrapper {
    padding: 0;
}

.ant-page-header-heading-title, .ant-page-header-back-button {
    color: floralwhite;
    mix-blend-mode: difference;
}
