@import '~antd/dist/antd.css';

.rainfall {
    height: 100%;
    width: 100%;
    margin: 0;

    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
}
